/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import fetchApi from "@/services/api-handler";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const reportError = async () => {
    await fetchApi(
      `slack/oos/postMessage`,
      {
        env: process.env.NEXT_PUBLIC_ENV,
        msg: `Global - ${error.message} ${error.stack}`,
      },
      "post"
    );
  };

  useEffect(() => {
    reportError();
  }, [error]);

  return (
    <div className="error-page">
      Sorry something went wrong... Please try again
    </div>
  );
}
