import { toast } from "react-hot-toast";


export default class ToastService {
    static async showSuccess(message: string) {
        toast.success(message);
    }

    static async showFailure(message: string) {
        toast.error(message);
    }
}