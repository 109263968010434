import axios, { AxiosError } from "axios";
import ToastService from "./toast.service";

const fetchApi = async (url: string, data = {}, method = 'post',) => {
    try {
        const response = await axios({
            method,
            url: `${process.env.NEXT_PUBLIC_API_URL}${url}`,
            data,
            headers: {
            }
        });
        
        if (response.data?.message) {
            ToastService.showSuccess(response.data?.message);
        }

        return response.data;
    } catch (ex) {
        if (ex instanceof AxiosError) {
            const data = ex.response?.data;
            switch(data?.status) {
                case 400: {
                    if (data?.message === 'Bad Request') break;
                    ToastService.showFailure(data?.message);
                    break;
                }
                case 422: {
                    data.errors.forEach((element: any) => {
                        ToastService.showFailure(element.message);
                      });
                    break;
                }
                default: {
                    ToastService.showFailure('Apologies for the inconvenience. Please try again later.');
                    break;
                }
            }
            return ex.response?.data;
        } else {
            return {
                status: 500,
                message: 'Apologies for the inconvenience. Please try again later.',
                data: null,
                errors: [],
            }
        }
    }
}

export default fetchApi;
